<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <el-form-item
        label="选举名称"
        :rules="[
          { required: true, message: '请输入选举名称', trigger: 'blur' },
        ]"
        prop="electionName"
      >
        <v-input
          placeholder="请输入选举名称"
          :width="width"
          v-model="form.electionName"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="上传图片"
        :rules="[
          {
            required: true,
            message: '请上传封面图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="pictureUrl"
      >
        <v-upload
          :limit="1"
          :imgUrls.sync="form.pictureUrl"
          tips="建议上传正方形图片"
        />
      </el-form-item>
      <el-form-item
        label="选择开始时间"
        :rules="[
          {
            required: true,
            message: '选择开始时间',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="startTime"
      >
        <v-date-picker
          v-model="form.startTime"
          type="date"
          formatValue="yyyy-MM-dd"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { saveStepURL, updateVoteBackStage } from "./api.js";
export default {
  name: "partnerForm",
  data() {
    return {
      width: 220,
      submitConfig: {
        submitUrl: updateVoteBackStage,
        queryMethod: "post",
      },
      form: {
        detailsId: "",
        electionId: "", //选举id 新增不传 编辑必传
        electionName: "", //选举名称
        pictureUrl: "", //图片
        startTime: "", //开始时间
      },
    };
  },
  created() {
    const { electionId, electionName, pictureUrl, startTime } =
      this.$route.query;
    if (electionId) {
      this.form.electionId = electionId;
      this.form.electionName = electionName;
      this.form.pictureUrl = pictureUrl;
      this.form.startTime = startTime;
    }
    this.$setBreadList(electionId ? "编辑" : "新增");
  },
  methods: {
    submitBefore() {
      if (!this.form.userId) {
        this.form.userId = this.$store.state.app.userInfo.userId;
      }
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .form-panel-container {
    .content {
      .el-form {
        .el-form-item {
          margin-bottom: 100px !important;
        }
      }
    }
  }
}
</style>
