var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divBox"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"选举名称","rules":[
          { required: true, message: '请输入选举名称', trigger: 'blur' },
        ],"prop":"electionName"}},[_c('v-input',{attrs:{"placeholder":"请输入选举名称","width":_vm.width},model:{value:(_vm.form.electionName),callback:function ($$v) {_vm.$set(_vm.form, "electionName", $$v)},expression:"form.electionName"}})],1),_c('el-form-item',{attrs:{"label":"上传图片","rules":[
          {
            required: true,
            message: '请上传封面图片',
            trigger: ['change', 'blur'],
          },
        ],"prop":"pictureUrl"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.pictureUrl,"tips":"建议上传正方形图片"},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "pictureUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "pictureUrl", $event)}}})],1),_c('el-form-item',{attrs:{"label":"选择开始时间","rules":[
          {
            required: true,
            message: '选择开始时间',
            trigger: ['change', 'blur'],
          },
        ],"prop":"startTime"}},[_c('v-date-picker',{attrs:{"type":"date","formatValue":"yyyy-MM-dd"},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }