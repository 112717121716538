//获取选举后台列表
const getvoteBackStageList = `/gateway/hc-govern/communityElection/getList`;
//选举后台新增/编辑
const updateVoteBackStage =
  "/gateway/hc-govern/communityElection/addOrUpdateInfo";
//删除
const deleteVoteBackStage = `/gateway/hc-govern/communityElection/deleteInfo`;
//保存步骤
const saveStepURL = `/gateway/hc-govern/communityElection/saveStep`;
// 获取步骤信息
const getStepInfoURL = `/gateway/hc-govern/communityElection/getStepInfo`;
export {
  getvoteBackStageList,
  updateVoteBackStage,
  deleteVoteBackStage,
  saveStepURL,
  getStepInfoURL,
};
